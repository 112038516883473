

































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',

      'filter',

      'documents', 'lastMeta', 'taskRunning',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ProductPatternList extends Vue {

  get titleStack() {
    return [
      'Шаблоны продуктов',
    ]
  }

  // ---------------------------------------------------------------------------

  get defaultSort() {
    return [this.$route.query.sortField || 'name', this.$route.query.sortDirection || 'asc']
  }

  public lastMeta!: any

  // ---------------------------------------------------------------------------

  public draggingRow: any = null
  public draggingRowIndex: any = null

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  public runTask() {
    this.$store.dispatch(`${STORE_KEY}/calcOldData`, this.$route.query)
  }

  public onSort(field: string, direction: string) {
    this.$router.replace({
      query: {
        ...this.$route.query, sortField: field, sortDirection: direction,
      },
    })
  }

  public onSearch(filter: string, value: any) {
    this.$router.replace({
      query: {
        ...this.$route.query, [filter]: value,
      },
    })
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.query)
  }

  public dragstart(payload: any) {
    this.draggingRow = payload.row
    this.draggingRowIndex = payload.index
    payload.event.dataTransfer.effectAllowed = 'copy'
  }

  public dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy'
    payload.event.target.closest('tr').classList.add('is-selected')
    payload.event.preventDefault()
  }

  public dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    payload.event.preventDefault()
  }

  public drop(payload: any) {
    const droppedOnRowIndex = payload.index
    payload.event.target.closest('tr').classList.remove('is-selected')

    const pageStart = this.lastMeta.pageSize * (this.lastMeta.page - 1)

    this.$store.dispatch(`${STORE_KEY}/updateOrder`, {
      id: this.draggingRow.id,
      newPosition: pageStart + droppedOnRowIndex + 1,
    })

  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
