import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosError, AxiosResponse } from 'axios'

interface ProductPatternListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  taskRunning: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: ProductPatternListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  taskRunning: true,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<ProductPatternListState, RootState> = {
  loadData({ commit, state }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1

      if (query.name) {
        params.search = query.name
      }

      commit('START_LOADING')

      Promise.all([
        vm.$http.get('/admins/rest/v1/product_patterns', { params }),
        vm.$http.get('/admins/rest/v1/receipts/classify-task'),
      ])
        .then(([patternResponse, taskStatusResponse]) => {
          state.taskRunning = taskStatusResponse.data.running
          commit('SET_ITEMS_LIST', patternResponse.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  calcOldData({ commit, state, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      commit('START_LOADING')

      state.taskRunning = true

      vm.$http
        .post('/admins/rest/v1/receipts/classify-task')
        .then((response: AxiosResponse) => {
          dispatch('loadData', {})
          resolve(response.data)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  updateOrder({ commit, dispatch }, { id, newPosition }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      vm.$http.patch(`/admins/rest/v1/product_patterns/${id}`, {
        product_pattern: {
          position: newPosition,
        },
      })
        .then((response: AxiosResponse) => {
          dispatch('loadData', {})
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error
            reject(serverError)
          }
        })
    })
  },
}

export const $mutations: MutationTree<ProductPatternListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.product_patterns

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

export const $getters: GetterTree<ProductPatternListState, RootState> = {}

export const STORE_KEY = '$_product_pattern_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
